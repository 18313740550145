$(".js-section-delete").on('click', function (e) {
    e.preventDefault();
    //get name of selected item
    var name = this.dataset.name;
    var accept = 'Are you sure you want to delete item ' + name + '?';
    var response = confirm(accept);
    //if user clicks ok form is submitted
    if (response === true) {
        $(this).parent().submit(); //parent element of this is form defined in components.buttons.delete
    } else {
        return;
    }
});